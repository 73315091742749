import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { AppUpdater } from "./app-updater";

export default function App () {
  return (
    <Router>
      <Switch>
        <AppUpdater interval={1 * 60 * 1000}>
          <div>
            <nav>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/contacts">Contacts</Link>
                </li>
                <li>
                  <Link to="/places">Places</Link>
                </li>
                <li>
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li>
                  <Link to="/settings">Settings</Link>
                </li>
                <li>
                  <Link to="/users">Users</Link>
                </li>
              </ul>
            </nav>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/dashboard">
                <Dashboard />
              </Route>
              <Route path="/places">
                <Places />
              </Route>
              <Route path="/contacts">
                <Contacts />
              </Route>
              <Route path="/users">
                <Users />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </AppUpdater>
      </Switch >
    </Router>
  );
}

function Home () {
  return <h2>Home</h2>;
}

function About () {
  return <h2>About</h2>;
}

function Users () {
  return <h2>Users</h2>;
}

function Contacts () {
  return <h2>Contacts</h2>;
}

function Dashboard () {
  return <h2>Dashboard</h2>;
}

function Places () {
  return <h2>Places</h2>;
}

function Settings () {
  return <h2>Settings</h2>;
}
