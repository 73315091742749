import {
  useCallback, useEffect, useState,
  type PropsWithChildren,
} from 'react'
import { useLocation } from 'react-router-dom'

interface AppUpdaterProviderProps {
  interval?: number | false
}

const DEFAULT_INTERVAL = 30 * 60 * 1000

export const AppUpdater = (props: PropsWithChildren<AppUpdaterProviderProps>) => {
  const { children, interval } = props

  const location = useLocation()
  const fetchInterval = interval ?? DEFAULT_INTERVAL

  const [hasToReload, setReload] = useState(false)

  const fetchUpdate = useCallback(() => {
    console.log('fetching');
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations()
        .then((registrations) => {
          const lastRegistration = registrations[0]
          lastRegistration?.update()
        })
    }
  }, [])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        setReload(true)
      })
    }
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: this effect checks when the location changes but it does not use it at all
  useEffect(() => {
    if (hasToReload) {
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    let intervalID: NodeJS.Timeout | number = -1
    if (fetchInterval) {
      intervalID = setInterval(fetchUpdate, fetchInterval)
    } else {
      fetchUpdate()
    }
    return () => (intervalID !== -1 ? clearInterval(intervalID as NodeJS.Timeout) : undefined)
  }, [fetchInterval, fetchUpdate])

  return <>{children}</>
}
